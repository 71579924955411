<template>
    <div>
        <dialog-reportings-comment ref="dialog_reportings" @report_removed="report_removed" @all_reports_removed="all_reports_removed"></dialog-reportings-comment>

        <div class="report-table-container">
            <v-data-table
                    :headers="headers"
                    :items="all_reports"
                    :page.sync="page"
                    :items-per-page="items_per_page"
                    hide-default-footer
                    sort-by="reports"
                    sort-desc
                    class="disable-select"
                    @page-count="pageCount = $event"
                    @click:row="open_reportings">
                <template slot="no-data">
                    <div></div>
                </template>
            </v-data-table>

            <div class="text-center pt-2">
                <v-pagination
                        v-model="page"
                        :length="pageCount"
                        :color="$variables.v.theme.primary"></v-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import dialog_reportings_comment from "../../components/dialogs/dialog-reportings-comment";


    export default {
        components: {
            "dialog-reportings-comment": dialog_reportings_comment
        },
        data() {
            return {
                page          : 1,
                pageCount     : 0,
                items_per_page: 20,
                headers       : [
                    {text: this.$t("administration.comment-reportings.table.postid"), value: "postID"},
                    {text: this.$t("administration.comment-reportings.table.commentid"), value: "commentID"},
                    {text: this.$t("administration.comment-reportings.table.reportamount"), value: "report_amount"},
                ],
                all_reports   : [],
            }
        },
        created() {
            this.get_reports();
        },
        methods   : {
            async get_reports() {
                let response = await this.func.ax("/php/administration.php", {
                    func  : "get_comment_reports",
                    userid: this.$user.user.id,
                    token : this.$user.user.token,
                });
                if (this.func.isObject(response)) {
                    this.all_reports = response;
                    this.all_reports = this.all_reports.map(function (item) {
                        return {postID: Number(item.postID), commentID: Number(item.commentID), report_amount: Number(item.report_amount)};
                    });
                }
                else
                    this.eventbus.$emit("SB", response, "error");
            },
            async open_reportings(element) {
                this.$refs.dialog_reportings.open_dialog(element.commentID);
            },
            report_removed(id) { // Decrease amount of reports when a report was reviewed
                this.all_reports.find(x => x.commentID === id).report_amount--;
                if (this.all_reports.find(x => x.commentID === id).report_amount === 0) {
                    let removeIndex = this.all_reports.map(function (item) {
                        return item.commentID;
                    }).indexOf(id);
                    this.all_reports.splice(removeIndex, 1);
                }
            },
            all_reports_removed(id) {
                let removeIndex = this.all_reports.map(function (item) {
                    return item.commentID;
                }).indexOf(id);
                this.all_reports.splice(removeIndex, 1)
            }
        }
    }
</script>