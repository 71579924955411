<template>
    <div>
        <dialog-inquiry ref="dialog_inquiry" @inquiry_solved="inquiry_solved"></dialog-inquiry>


        <v-expansion-panels class="mb-5">
            <v-expansion-panel class="expansion-box">
                <v-expansion-panel-header>
                    <span class="font-weight-bold">{{$t('administration.search-fields')}}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="d-flex">
                        <v-text-field
                                v-model="search_id"
                                append-icon="mdi-magnify"
                                :label="$t('administration.support-inquiries.search.caseid')"
                                :color="$variables.v.theme.secondary"
                                maxlength="150"
                                class="default-textfield-width ml-10"
                                @keyup.enter="get_inquiries"></v-text-field>
                        <v-text-field
                                v-model="search_email"
                                append-icon="mdi-magnify"
                                :label="$t('administration.support-inquiries.search.email')"
                                :color="$variables.v.theme.secondary"
                                maxlength="30"
                                class="default-textfield-width ml-10"
                                @keyup.enter="get_inquiries"></v-text-field>
                        <v-text-field
                                v-model="search_subject"
                                append-icon="mdi-magnify"
                                :label="$t('administration.support-inquiries.search.subject')"
                                :color="$variables.v.theme.secondary"
                                maxlength="30"
                                class="default-textfield-width ml-10"
                                @keyup.enter="get_inquiries"></v-text-field>
                        <v-text-field
                                v-model="search_limit"
                                append-icon="mdi-magnify"
                                :label="$t('administration.support-inquiries.search.limit')"
                                :color="$variables.v.theme.secondary"
                                maxlength="6"
                                class="small-textfield-width ml-10"
                                @keyup.enter="get_inquiries"></v-text-field>

                        <v-checkbox class="mr-3 ml-10"
                                    v-model="hide_solved"
                                    :label="$t('administration.support-inquiries.search.hide-solved')"
                                    :color="$variables.v.theme.secondary"
                                    @change="get_inquiries"></v-checkbox>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>


        <v-data-table
                :headers="headers"
                :items="inquiries"
                :page.sync="page"
                :items-per-page="items_per_page"
                hide-default-footer
                sort-by="reports"
                sort-desc
                class="disable-select"
                @page-count="pageCount = $event"
                @click:row="open_inquiry">
            <template slot="no-data">
                <div></div>
            </template>
            <template v-slot:item.category="{ item }">
                <span v-if="$variables.v.support_categories.find(x => x.id === Number(item.category)) !== undefined">
                    {{$t($variables.v.support_categories.find(x => x.id === Number(item.category)).text)}}
                </span>
            </template>
        </v-data-table>

        <div class="text-center pt-2">
            <v-pagination
                    v-model="page"
                    :length="pageCount"
                    :color="$variables.v.theme.primary"></v-pagination>
        </div>
    </div>
</template>

<script>
    import dialog_inquiry from "../dialogs/dialog-inquiry";


    export default {
        components: {
            "dialog-inquiry": dialog_inquiry
        },
        data() {
            return {
                page          : 1,
                pageCount     : 0,
                items_per_page: 20,
                search_id     : "",
                search_email  : "",
                search_subject: "",
                search_limit  : "100",
                hide_solved   : true,
                headers       : [
                    {text: this.$t("administration.support-inquiries.table.caseid"), value: "ID"},
                    {text: this.$t("administration.support-inquiries.table.email"), value: "email"},
                    {text: this.$t("administration.support-inquiries.table.category"), value: "category"},
                    {text: this.$t("administration.support-inquiries.table.subject"), value: "subject"},
                    {text: this.$t("administration.support-inquiries.table.time"), value: "timestamp"},
                ],
                inquiries     : [],
            }
        },
        created() {
            this.get_inquiries();
        },
        computed  : {
            c_search_limit() {
                return isNaN(this.search_limit.trim()) ? this.search_limit_default : this.search_limit.trim();
            },
        },
        methods   : {
            async get_inquiries() {
                let response = await this.func.ax("/php/administration.php", {
                    func          : "get_inquiries",
                    userid        : this.$user.user.id,
                    token         : this.$user.user.token,
                    search_id     : this.search_id.trim(),
                    search_email  : this.search_email.trim(),
                    search_subject: this.search_subject.trim(),
                    hide_solved   : this.hide_solved,
                    search_limit  : this.c_search_limit,
                });
                if (this.func.isObject(response))
                    this.inquiries = response;
                else
                    this.eventbus.$emit("SB", response, "error");
            },
            open_inquiry(inquiry) {
                this.$refs.dialog_inquiry.open_dialog(inquiry.ID, !this.hide_solved)
            },
            inquiry_solved(id) {
                let removeIndex = this.inquiries.map(function (item) {
                    return item.ID;
                }).indexOf(id);
                this.inquiries.splice(removeIndex, 1)
            }
        }
    }
</script>