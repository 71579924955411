<template>
    <div>
        <dialog-user ref="dialog_user" @user_updated="update_user"></dialog-user>
        <!--Search strings-->
        <v-expansion-panels class="mb-5">
            <v-expansion-panel class="expansion-box">
                <v-expansion-panel-header>
                    <span class="font-weight-bold">{{$t('administration.search-fields')}}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="d-flex">
                        <v-text-field
                                v-model="search_username"
                                append-icon="mdi-magnify"
                                :label="$t('administration.user-administration.search.username')"
                                :color="$variables.v.theme.secondary"
                                maxlength="30"
                                class="default-textfield-width"
                                @keyup.enter="get_users"></v-text-field>
                        <v-text-field
                                v-model="search_email"
                                append-icon="mdi-magnify"
                                :label="$t('administration.user-administration.search.email')"
                                :color="$variables.v.theme.secondary"
                                maxlength="150"
                                class="default-textfield-width ml-10"
                                @keyup.enter="get_users"></v-text-field>
                        <v-text-field
                                v-model="search_ip"
                                append-icon="mdi-magnify"
                                :label="$t('administration.user-administration.search.ip')"
                                :color="$variables.v.theme.secondary"
                                maxlength="50"
                                class="default-textfield-width ml-10"
                                @keyup.enter="get_users"></v-text-field>
                        <v-text-field
                                v-model="search_limit"
                                append-icon="mdi-magnify"
                                :label="$t('administration.post-overview.search.limit')"
                                :color="$variables.v.theme.secondary"
                                maxlength="6"
                                class="small-textfield-width ml-10"
                                @keyup.enter="get_users"></v-text-field>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>


        <!--Hide columns-->
        <v-expansion-panels class="mb-5">
            <v-expansion-panel class="expansion-box">
                <v-expansion-panel-header>
                    <span class="font-weight-bold">{{$t("administration.hide-show-columns")}}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="d-flex">
                        <v-checkbox class="mr-3"
                                    v-model="show_extended_user_info"
                                    :label="$t('administration.user-administration.show-extended-user-info')"
                                    :color="$variables.v.theme.secondary"
                                    @change="get_users"></v-checkbox>

                        <v-checkbox class="mr-3"
                                    v-model="show_login_info"
                                    :label="$t('administration.user-administration.show-login-info')"
                                    :color="$variables.v.theme.secondary"
                                    @change="get_users"></v-checkbox>

                        <v-checkbox class="mr-3"
                                    v-model="show_system_info"
                                    :label="$t('administration.user-administration.show-system-info')"
                                    :color="$variables.v.theme.secondary"
                                    @change="get_users"></v-checkbox>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>


        <v-data-table
                :headers="c_headers"
                :items="users"
                :page.sync="page"
                :items-per-page="items_per_page"
                hide-default-footer
                multi-sort
                :item-class="row_color"
                @page-count="pageCount = $event"
                @click:row="open_user">
            <template slot="no-data">
                <div></div>
            </template>
            <template v-slot:item.roleID="{ item }">
                {{$t($variables.v.roles.find(x => x.id === item.roleID).name)}}
            </template>
            <template v-slot:item.email_verified="{ item }">
                {{item.email_verified === "1"}}
            </template>
        </v-data-table>

        <div class="text-center pt-2">
            <v-pagination
                    v-model="page"
                    :length="pageCount"
                    :color="$variables.v.theme.primary"></v-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                users                  : [],
                page                   : 1,
                pageCount              : 0,
                items_per_page         : 20,
                search_username        : "",
                search_email           : "",
                search_ip              : "",
                search_limit           : "100",
                search_limit_default   : 100,
                show_extended_user_info: false,
                show_login_info        : false,
                show_system_info       : false,
            }
        },
        computed: {
            c_search_limit() {
                return isNaN(this.search_limit.trim()) ? this.search_limit_default : this.search_limit.trim();
            },
            c_headers() {
                let headers = [
                    {text: this.$t("administration.user-administration.user.userid"), value: "userID"},
                    {text: this.$t("administration.user-administration.user.username"), value: "username"},
                    {text: this.$t("administration.user-administration.user.email"), value: "email"},
                    {text: this.$t("administration.user-administration.user.role"), value: "roleID"},
                ];

                if (this.show_extended_user_info)
                    headers.push(
                        {text: this.$t("administration.user-administration.user.verified"), value: "email_verified"},
                        {text: this.$t("administration.user-administration.user.language"), value: "language"},
                        {text: this.$t("administration.user-administration.user.banned"), value: "banned"},
                        {text: this.$t("administration.user-administration.user.points"), value: "points"},
                        {text: this.$t("administration.user-administration.user.last-username-change"), value: "last_username_change"},
                        {text: this.$t("administration.user-administration.user.register-date"), value: "register_date"},
                    );

                if (this.show_login_info)
                    headers.push(
                        {text: this.$t("administration.user-administration.user.last-login"), value: "last_login"},
                        {text: this.$t("administration.user-administration.user.last-login-ip"), value: "last_login_ip"},
                    );

                if (this.show_system_info)
                    headers.push(
                        {text: this.$t("administration.user-administration.user.browser-lang"), value: "browser_lang"},
                        {text: this.$t("administration.user-administration.user.browser-name"), value: "browser_name"},
                        {text: this.$t("administration.user-administration.user.browser-useragent"), value: "browser_useragent"},
                        {text: this.$t("administration.user-administration.user.browser-version"), value: "browser_version"},
                        {text: this.$t("administration.user-administration.user.screen-height"), value: "screen_height"},
                        {text: this.$t("administration.user-administration.user.screen-width"), value: "screen_width"},
                    );
                return headers;
            }
        },
        created() {
            this.get_users();
        },
        methods : {
            row_color(item) { // Font color when banned
                return item.banned === "permanent" ? "error--text" : item.banned === "temporary" ? "warning--text" : "";
            },
            async get_users() {
                let response = await this.func.ax("/php/administration.php", {
                    func           : "get_users",
                    userid         : this.$user.user.id,
                    token          : this.$user.user.token,
                    search_username: this.search_username.trim(),
                    search_email   : this.search_email.trim(),
                    search_ip      : this.search_ip.trim(),
                    search_limit   : this.c_search_limit,
                });
                await this.$root.check_response_permission(response, () => {
                    this.users = response;
                }, response);
            },
            open_user(user) {
                this.$refs.dialog_user.open_dialog(user.userID);
            },
            update_user(user) {
                this.users.find(x => x.userID === user.userID).username = user.username;
                this.users.find(x => x.userID === user.userID).email    = user.email;
                this.users.find(x => x.userID === user.userID).points   = user.points;
                this.users.find(x => x.userID === user.userID).roleID   = user.roleID;
                this.users.find(x => x.userID === user.userID).banned   = user.banned;
            },

        },
    }
</script>