<template>
    <v-dialog v-model="dialog" v-if="comment !== null" width="700px">

        <dialog-confirm ref="confirm"></dialog-confirm>

        <dialog-post ref="dialog_post" :adminmode="true"></dialog-post>

        <v-card flat class="px-4 fill-height grey darken-3">
            <dragger></dragger>

            <div v-if="comment !== undefined && comment !== null" class="reported-comment-border">
                <div class="ml-5 font-weight-bold">
                    <div class="mb-2 lime--text">
                        {{$t("administration.comment-reportings.reported-comment")}}
                    </div>
                    <miniview-user :user="comment"></miniview-user>
                    <div class="comment-created-at">
                        {{c_created_at}}
                    </div>
                    <div class="mt-2">
                        {{comment.comment}}
                    </div>
                </div>
            </div>

            <div v-for="report in reports"
                 :key="report.ID"
                 class="pt-5">
                <div class="d-flex">
                    <div v-if="$variables.v.enable_report_rewards">
                        <v-icon @click.once="remove_report(report.ID, 0)">
                            mdi-close
                        </v-icon>
                        <v-icon @click.once="remove_report(report.ID, 1)" class="ml-5">
                            mdi-check
                        </v-icon>
                    </div>
                    <miniview-user :user="report" :class="{'ml-5':$variables.v.enable_report_rewards}"></miniview-user>
                </div>
                <div class="mt-5 mb-5">
                    {{$variables.v.comment_report_reasons.find(x => x.text === report.reason) ? $t(report.reason) : report.reason}}
                </div>
                <v-divider></v-divider>
            </div>
            <v-row>
                <v-col>
                    <v-icon @click.once="remove_all_reports" :key="unlock_disable_all_reports">
                        mdi-check-all
                    </v-icon>

                    <div class="float-right">
                        <v-btn v-if="this.comment.disabled === '0'"
                               class="administration-btn"
                               depressed
                               @click.once="delete_comment"
                               :key="unlock_delete_comment">
                            {{$t("administration.comment-reportings.delete-comment")}}
                        </v-btn>

                        <v-btn class="administration-btn"
                               depressed
                               @click.once="open_post"
                               :key="unlock_open_post">
                            {{$t("administration.comment-reportings.open-post")}}
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        data() {
            return {
                dialog                    : false,
                unlock_disable_all_reports: 0,
                unlock_delete_comment     : 100000,
                unlock_open_post          : 200000,
                reports                   : [],
                comment                   : null,
            }
        },
        watch   : {
            dialog() {
                if (!this.dialog) {
                    this.reports = [];
                    this.comment = null;
                }
            }
        },
        computed: {
            c_created_at() {
                let current_year = new Date().getFullYear();
                let year         = Number(this.moment(this.comment.created_at).format("YYYY"));
                let format       = current_year === year ? "DD.MM - HH:mm" : "DD.MM.YYYY - HH:mm";
                return this.moment(this.comment.created_at).format(format);
            }
        },
        beforeCreate() {
            this.func.vuetify_dragable_dialogs();
        },
        methods : {
            async open_dialog(id) {
                this.comment = await this.func.ax("/php/administration.php", {
                    func     : "get_reported_comment",
                    userid   : this.$user.user.id,
                    token    : this.$user.user.token,
                    commentid: id,
                });
                let response = await this.func.ax("/php/administration.php", {
                    func     : "get_reports_from_comment",
                    userid   : this.$user.user.id,
                    token    : this.$user.user.token,
                    commentid: id,
                });
                if (this.func.isObject(response)) {
                    this.reports = response;
                    this.id      = id;
                    this.dialog  = true;
                }
                else
                    this.eventbus.$emit("SB", response, "error");
            },
            open_post() {
                this.$refs.dialog_post.open_dialog(this.comment.postID);
                this.unlock_open_post++;
            },
            async remove_report(reportid, accepted) {
                let response = await this.func.ax("/php/administration.php", {
                    func    : "remove_comment_report",
                    userid  : this.$user.user.id,
                    token   : this.$user.user.token,
                    reportid: reportid,
                    accepted: accepted,
                    reward  : this.$variables.v.report_usefull_reward,
                });

                await this.$root.check_response_permission(response, () => {
                    let removeIndex = this.reports.map(function (item) {
                        return item.ID;
                    }).indexOf(reportid);
                    this.reports.splice(removeIndex, 1);
                    if (this.reports.length === 0)
                        this.dialog = false;
                    this.$emit("report_removed", this.id);
                }, response);

                this.unlock_disable_all_reports++;
            },
            async remove_all_reports() {
                let response = await this.func.ax("/php/administration.php", {
                    func     : "remove_all_comment_reports",
                    userid   : this.$user.user.id,
                    token    : this.$user.user.token,
                    commentid: this.id,
                });

                await this.$root.check_response_permission(response, () => {
                    this.$emit("all_reports_removed", this.id);
                    this.dialog = false;
                }, response);

                this.unlock_disable_all_reports++;
            },
            async delete_comment() {
                if (await this.$refs.confirm.open(this.$t("dialog-post.dialog-confirm.delete-comment"), this.$t("dialog-post.dialog-confirm.are-you-sure"))) {
                    if (this.$user.user.id === this.comment.userID || this.$root.check_mod()) {
                        let response = await this.func.ax("/php/post_comment_delete.php", {
                            commentid: this.comment.ID,
                            userid   : this.$user.user.id,
                            token    : this.$user.user.token,
                        });
                        await this.$root.check_response(response, () => {
                            this.$emit("comment_removed");
                            this.comment.disabled = '1';
                            this.eventbus.$emit("SB", "dialog-post.msg.comment-is-deleted", "success", 5);
                        }, "dialog-post.msg.comment-remove-failed");
                    }
                }
                this.unlock_delete_comment++;
            },
        },
    }
</script>