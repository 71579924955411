<template>
    <v-dialog v-model="dialog" width="700px">

        <dialog-post ref="dialog_post" :adminmode="true"></dialog-post>

        <v-card flat class="px-4 fill-height grey darken-3">
            <dragger></dragger>
            <div v-for="report in reports"
                 :key="report.ID"
                 class="pt-5">
                <div class="d-flex">
                    <div v-if="$variables.v.enable_report_rewards">
                        <v-icon @click.once="remove_report(report.ID, 0)">
                            mdi-close
                        </v-icon>
                        <v-icon @click.once="remove_report(report.ID, 1)" class="ml-5">
                            mdi-check
                        </v-icon>
                    </div>
                    <miniview-user :user="report" :class="{'ml-5':$variables.v.enable_report_rewards}"></miniview-user>
                </div>
                <div class="mt-5 mb-5">
                    {{$variables.v.post_report_reasons.find(x => x.text === report.reason) ? $t(report.reason) : report.reason}}
                </div>
                <v-divider></v-divider>
            </div>
            <v-row>
                <v-col>
                    <v-icon @click.once="remove_all_reports" :key="unlock_disable_all_reports">
                        mdi-check-all
                    </v-icon>

                    <div class="float-right">
                        <v-btn class="administration-btn"
                               depressed
                               @click.once="open_post"
                               :key="unlock_open_post">
                            {{$t("administration.post-reportings.open-post")}}
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        data() {
            return {
                dialog                    : false,
                unlock_disable_all_reports: 0,
                unlock_open_post          : 100000,
                reports                   : [],
                id                        : null,
            }
        },
        watch  : {
            dialog() {
                if (!this.dialog) {
                    this.id      = null;
                    this.reports = [];
                }
            }
        },
        beforeCreate() {
            this.func.vuetify_dragable_dialogs();
        },
        methods: {
            async open_dialog(id) {
                let response = await this.func.ax("/php/administration.php", {
                    func  : "get_reports_from_post",
                    userid: this.$user.user.id,
                    token : this.$user.user.token,
                    postid: id,
                });
                if (this.func.isObject(response)) {
                    this.reports = response;
                    this.id      = id;
                    this.dialog  = true;
                }
                else
                    this.eventbus.$emit("SB", response, "error");
            },
            open_post() {
                this.$refs.dialog_post.open_dialog(this.id);
                this.unlock_open_post++;
            },
            async remove_report(reportid, accepted) {
                let response = await this.func.ax("/php/administration.php", {
                    func    : "remove_post_report",
                    userid  : this.$user.user.id,
                    token   : this.$user.user.token,
                    reportid: reportid,
                    accepted: accepted,
                    reward  : this.$variables.v.report_usefull_reward,
                });
                await this.$root.check_response_permission(response, () => {
                    let removeIndex = this.reports.map(function (item) {
                        return item.ID;
                    }).indexOf(reportid);
                    this.reports.splice(removeIndex, 1);
                    if (this.reports.length === 0)
                        this.dialog = false;
                    this.$emit("report_removed", this.id);
                }, response);

                this.unlock_disable_all_reports++;
            },
            async remove_all_reports() {
                let response = await this.func.ax("/php/administration.php", {
                    func  : "remove_all_post_reports",
                    userid: this.$user.user.id,
                    token : this.$user.user.token,
                    postid: this.id,
                });
                await this.$root.check_response_permission(response, () => {
                    this.$emit("all_reports_removed", this.id);
                    this.dialog = false;
                }, response);

                this.unlock_disable_all_reports++;
            }
        },
    }
</script>