<template>
    <div class="animate__animated animate__fadeIn">
        <v-tabs v-model="selected_tab"
                :vertical="$root.windowWidth >= 800"
                :color="$variables.v.theme.secondary">
            <vertical-tabs v-for="navpoint in c_admin_navigation" :key="navpoint.id" :navpoint="navpoint"></vertical-tabs>

            <v-tab-item>
                <post-reportings :key="key_post_reportings"></post-reportings>
            </v-tab-item>

            <v-tab-item>
                <comment-reportings :key="key_comment_reportings"></comment-reportings>
            </v-tab-item>

            <v-tab-item>
                <post-overview :key="key_post_overview"></post-overview>
            </v-tab-item>

            <v-tab-item>
                <user-administration :key="key_user_administration"></user-administration>
            </v-tab-item>

            <v-tab-item>
                <support-inquiries :key="key_support_inquiries"></support-inquiries>
            </v-tab-item>

            <v-tab-item v-if="$root.check_superadmin()">
                <general-administration :key="key_global_administration"></general-administration>
            </v-tab-item>

        </v-tabs>
    </div>

</template>

<script>
    import c1_post_reportings from "../components/sub-administration/c1-post-reportings";
    import c2_comment_reportings from "../components/sub-administration/c2-comment-reportings";
    import c3_post_overview from "../components/sub-administration/c3-post-overview";
    import c4_user_administration from "../components/sub-administration/c4-user-administration";
    import c5_support_inquiries from "../components/sub-administration/c5-support-inquiries";
    import c6_general_administration from "../components/sub-administration/c6-general-administration";


    export default {
        components: {
            "post-reportings"       : c1_post_reportings,
            "comment-reportings"    : c2_comment_reportings,
            "post-overview"         : c3_post_overview,
            "user-administration"   : c4_user_administration,
            "support-inquiries"     : c5_support_inquiries,
            "general-administration": c6_general_administration
        },
        data() {
            return {
                selected_tab             : 0,
                key_post_reportings      : 0,
                key_comment_reportings   : 100000,
                key_post_overview        : 200000,
                key_user_administration  : 300000,
                key_support_inquiries    : 400000,
                key_global_administration: 500000,
            }
        },
        computed  : {
            c_admin_navigation() {
                let navigation = [
                    {id: 1, icon: "mdi-flag", text: "administration.nav.post-reportings"},
                    {id: 2, icon: "mdi-flag", text: "administration.nav.comment-reportings"},
                    {id: 3, icon: "mdi-post", text: "administration.nav.post-overview"},
                    {id: 4, icon: "mdi-account", text: "administration.nav.user-administration"},
                    {id: 5, icon: "mdi-email-multiple", text: "administration.nav.support-inquiries"}
                ];
                if (this.$root.check_superadmin())
                    navigation.push({id: 6, icon: "mdi-desktop-mac-dashboard", text: "administration.nav.general-administration"});

                return navigation;
            },
        },
        watch     : {
            selected_tab() {
                switch (this.selected_tab) {
                    case 0:
                        this.key_post_reportings++;
                        break;
                    case 1:
                        this.key_comment_reportings++;
                        break;
                    case 2:
                        this.key_post_overview++;
                        break;
                    case 3:
                        this.key_user_administration++;
                        break;
                    case 4:
                        this.key_support_inquiries++;
                        break;
                    case 5:
                        this.key_global_administration++;
                        break;
                }
            }
        },
        beforeRouteEnter(to, from, next) { // Prevent not signed in user from access this site
            next(vm => {
                if (!vm.$root.check_mod())
                    next({path: vm.$variables.v.navigation.page404})
            })
        },
        metaInfo() {
            return {
                title: `${this.$t('meta.page-title-unformated')} - ${this.$t('meta.page-title-administration')}`,
                meta : [{
                    name   : "description",
                    content: this.$t("meta.description"),
                }]
            }
        },
    }
</script>