<template>
    <div>
        <dialog-confirm ref="confirm"></dialog-confirm>
        <loading-circle v-if="loading"></loading-circle>
        <v-form v-if="!loading" v-model="valid" ref="r_form_settings">

            <v-col cols="12">
                <div class="text-center">
                    <v-btn x-large
                           depressed
                           :key="unlock_save_button"
                           :disabled="!valid"
                           color="success"
                           @click="save_settings">
                        {{$t("administration.general.save-server-settings")}}
                    </v-btn>
                </div>
            </v-col>

            <v-expansion-panels class="mb-5">
                <v-expansion-panel class="expansion-box">
                    <v-expansion-panel-header>
                        <span class="font-weight-bold">{{$t('administration.general.upcoming-features')}}</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-col cols="12">
                            <v-textarea v-model="settings.upcoming_features"
                                        :label="$t('administration.general.upcoming-features')"
                                        :color="$variables.v.theme.secondary"
                                        outlined
                                        dense
                                        auto-grow
                                        :rules="[rules.required]"></v-textarea>
                        </v-col>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>


            <v-expansion-panels class="mb-5">
                <v-expansion-panel class="expansion-box">
                    <v-expansion-panel-header>
                        <span class="font-weight-bold">{{$t('administration.general.privacy')}}</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-col cols="12">
                            <v-textarea v-model="settings.privacy"
                                        :label="$t('administration.general.privacy')"
                                        :color="$variables.v.theme.secondary"
                                        outlined
                                        dense
                                        auto-grow
                                        :rules="[rules.required]"></v-textarea>
                        </v-col>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>


            <v-expansion-panels class="mb-5">
                <v-expansion-panel class="expansion-box">
                    <v-expansion-panel-header>
                        <span class="font-weight-bold">{{$t('administration.general.imprint')}}</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-col cols="12">
                            <v-textarea v-model="settings.imprint"
                                        :label="$t('administration.general.imprint')"
                                        :color="$variables.v.theme.secondary"
                                        outlined
                                        dense
                                        auto-grow
                                        :rules="[rules.required]"></v-textarea>
                        </v-col>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>


            <v-expansion-panels class="mb-5">
                <v-expansion-panel class="expansion-box">
                    <v-expansion-panel-header>
                        <span class="font-weight-bold">{{$t('administration.general.variables')}}</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-layout>
                            <v-col cols="12" xl="6">
                                <pre v-html="variablepreview" class="syntax-highlight"></pre>
                            </v-col>

                            <v-col cols="12" xl="6">
                                <v-textarea v-model="settings.variables"
                                            :label="$t('administration.general.variables')"
                                            :color="$variables.v.theme.secondary"
                                            outlined
                                            dense
                                            auto-grow
                                            :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-layout>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-form>


        <div :key="unlock_options" class="disable-select">
            <v-col cols="12">
                <v-card-title>{{$t("administration.general.general-actions")}}</v-card-title>
                <v-btn depressed
                       class="administration-btn"
                       @click.once="refresh_leaderboard">
                    {{$t("administration.general.refresh-leaderboard")}}
                </v-btn>

                <v-btn depressed
                       class="administration-btn"
                       @click.once="deauthorize_all">
                    {{$t("administration.general.deauthorize-all")}}
                </v-btn>
            </v-col>

            <v-col cols="12">
                <v-card-title>{{$t("administration.general.reset-preferences")}}</v-card-title>
                <v-btn depressed
                       class="administration-btn"
                       @click.once="reset_all_preferences">
                    {{$t("administration.general.reset-all")}}
                </v-btn>

                <v-btn depressed
                       class="administration-btn"
                       @click.once="reset_hidden('services')">
                    {{$t("administration.general.reset-hidden-services")}}
                </v-btn>

                <v-btn depressed
                       class="administration-btn"
                       @click.once="reset_hidden('itemtypes')">
                    {{$t("administration.general.reset-hidden-itemtypes")}}
                </v-btn>

                <v-btn depressed
                       class="administration-btn"
                       @click.once="reset_hidden('navigation')">
                    {{$t("administration.general.reset-hidden-navigation")}}
                </v-btn>
            </v-col>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                valid          : false,
                loading        : false,
                settings       : {},
                settings_backup: {},

                variables      : "",
                variablepreview: "",

                unlock_save_button: 0,
                unlock_options    : 100000,
                rules             : {
                    required: v => (!!v) || this.$t("rules.required"),
                }
            }
        },
        created() {
            this.loading = true;
            this.load_settings();
            this.loading = false;
        },
        methods: {
            syntaxHighlight(json) {
                json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
                // eslint-disable-next-line no-useless-escape
                return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
                    var cls = 'syntax-highlight-number';
                    if (/^"/.test(match))
                        if (/:$/.test(match))
                            cls = 'syntax-highlight-key';
                        else
                            cls = 'syntax-highlight-string';
                    else if (/true|false/.test(match))
                        cls = 'syntax-highlight-boolean';
                    else if (/null/.test(match))
                        cls = 'syntax-highlight-null';

                    return '<span class="' + cls + '">' + match + '</span>';
                });
            },
            async load_settings() {
                let response = await this.func.ax("/php/administration.php", {
                    func  : "get_settings",
                    userid: this.$user.user.id,
                    token : this.$user.user.token,
                });
                await this.$root.check_response_permission(response, () => {
                    this.settings        = JSON.parse(JSON.stringify(response, null, 2));
                    this.settings_backup = JSON.parse(JSON.stringify(response));
                    this.variablepreview = this.syntaxHighlight(this.settings.variables);
                }, response);
            },
            async save_settings() {
                if (this.$refs.r_form_settings.validate()) {

                    try {
                        // Check if valid json format
                        JSON.parse(this.settings.variables);

                        let response = await this.func.ax("/php/administration_general.php", {
                            func    : "set_settings",
                            userid  : this.$user.user.id,
                            token   : this.$user.user.token,
                            settings: this.settings,
                        });
                        await this.$root.check_response(response, async () => {
                            this.eventbus.$emit("SB", "administration.msg.success", "success", 5);
                        }, "administration.msg.error");
                    } catch (e) {
                        this.eventbus.$emit("SB", "administration.msg.invalid-json", "error", 5);
                    }
                }
            },
            async refresh_leaderboard() {
                if (await this.$refs.confirm.open(this.$t("administration.general.refresh-leaderboard"), this.$t("dialog-confirm.are-you-sure"))) {
                    let response = await this.func.ax("/php/administration_general.php", {
                        func  : "refresh_leaderboard",
                        userid: this.$user.user.id,
                        token : this.$user.user.token,
                    });
                    await this.$root.check_response(response, () => {
                        this.eventbus.$emit("SB", "administration.msg.success", "success", 5);
                    }, "administration.msg.error");
                }
                this.unlock_options++;
            },
            async deauthorize_all() {
                if (await this.$refs.confirm.open(this.$t("administration.general.deauthorize-all"), this.$t("dialog-confirm.are-you-sure"))) {
                    let response = await this.func.ax("/php/administration_general.php", {
                        func  : "deauthorize_all",
                        userid: this.$user.user.id,
                        token : this.$user.user.token,
                    });
                    await this.$root.check_response(response, () => {
                        this.eventbus.$emit("SB", "administration.msg.success", "success", 5);
                    }, "administration.msg.error");
                }
                this.unlock_options++;
            },
            async reset_all_preferences() {
                if (await this.$refs.confirm.open(this.$t("dialog-confirm.title"), this.$t("dialog-confirm.are-you-sure"))) {
                    let response = await this.func.ax("/php/administration_general.php", {
                        func  : "reset_all_preferences",
                        userid: this.$user.user.id,
                        token : this.$user.user.token,
                    });
                    await this.$root.check_response(response, () => {
                        this.eventbus.$emit("SB", "administration.msg.success", "success", 5);
                    }, "administration.msg.error");
                }
                this.unlock_options++;
            },
            async reset_hidden(type) {
                if (await this.$refs.confirm.open(this.$t("dialog-confirm.title"), this.$t("dialog-confirm.are-you-sure"))) {
                    let response = await this.func.ax("/php/administration_general.php", {
                        func  : "reset_preference",
                        userid: this.$user.user.id,
                        token : this.$user.user.token,
                        type  : type,
                    });
                    await this.$root.check_response(response, () => {
                        this.eventbus.$emit("SB", "administration.msg.success", "success", 5);
                    }, "administration.msg.error");
                }
                this.unlock_options++;
            }
        }
    }
</script>