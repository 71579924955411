<template>
    <v-dialog v-model="dialog" v-if="inquiry !== null && inquiry" width="700px">
        <v-card flat class="px-4 fill-height grey darken-3">
            <dragger></dragger>

            <v-form v-model="valid" ref="r_form_send_inquiry_reply" class="py-4">
                <v-row>
                    <v-col v-if="inquiry.userID !== null">
                        <miniview-user :user="inquiry"></miniview-user>
                    </v-col>

                    <v-col class="text-right">{{c_created_at}}</v-col>
                </v-row>


                <v-row>
                    <v-col cols="12" sm="3">{{$t("administration.support-inquiries.sender-email")}}</v-col>

                    <v-col cols="12" sm="9">{{inquiry.email}}</v-col>
                </v-row>


                <v-row>
                    <v-col cols="12" sm="3">{{$t("administration.support-inquiries.sender-ip")}}</v-col>

                    <v-col cols="12" sm="9">{{inquiry.ip}}</v-col>
                </v-row>


                <v-row>
                    <v-col cols="12" sm="3">{{$t("administration.support-inquiries.category")}}</v-col>

                    <v-col cols="12" sm="9">{{$t($variables.v.support_categories.find(x => x.id === Number(inquiry.category)).text)}}</v-col>
                </v-row>


                <v-row>
                    <v-col cols="12" sm="3">{{$t("administration.support-inquiries.subject")}}</v-col>

                    <v-col cols="12" sm="9">{{inquiry.subject}}</v-col>
                </v-row>


                <v-row>
                    <v-col cols="12" sm="3">{{$t("administration.support-inquiries.message")}}</v-col>

                    <v-col cols="12" sm="9" class="content-from-textarea">{{inquiry.message}}</v-col>
                </v-row>

                <v-row v-if="inquiry.our_replies.length > 0">
                    <v-col cols="12">
                        <v-expansion-panels class="mb-5">
                            <v-expansion-panel class="expansion-box">
                                <v-expansion-panel-header>
                                    <span class="font-weight-bold">{{$t('administration.support-inquiries.our-replies')}}</span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <div v-for="(reply, index) in inquiry.our_replies" :key="index" class="mb-2">
                                        <div class="mb-2 support-our-replies">
                                            <router-link
                                                    class="disable-select no-link-color"
                                                    :to="$variables.v.navigation.profile_id + reply.userID">{{reply.username}} - {{reply.timestamp}}:
                                            </router-link>
                                            <div class="content-from-textarea">{{reply.text}}</div>
                                        </div>
                                    </div>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                </v-row>


                <v-textarea
                        v-if="inquiry.solved === '0'"
                        v-model="reply"
                        :color="$variables.v.theme.secondary"
                        :placeholder="$t('administration.support-inquiries.reply-message')"
                        :rules="[rules.min10]"
                        counter
                        outlined
                        auto-grow
                        no-resize
                        rows="2"
                        @click="comment_clicked = true"></v-textarea>

                <div :key="unlock_inquiry_actions">
                    <v-btn depressed
                           :disabled="!valid || inquiry.solved === '1'"
                           class="administration-btn"
                           @click.once="send_reply">
                        {{$t("administration.support-inquiries.send-reply")}}
                    </v-btn>

                    <v-btn depressed
                           :disabled="inquiry.solved === '1'"
                           class="administration-btn"
                           @click.once="mark_solved">
                        {{$t("administration.support-inquiries.solved")}}
                    </v-btn>
                </div>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        data() {
            return {
                dialog                : false,
                valid                 : false,
                id                    : null, // ID of the inquiry
                show_solved           : null, // If true ignore that the inquiry is solved (in table solved must be visible!)
                inquiry               : null,
                reply                 : "", // Our replytext
                unlock_inquiry_actions: 0,
                rules                 : {
                    min10: v => (v !== undefined && v.length >= 10) || this.$t("rules.min-10-chars"),
                },
            }
        },
        watch   : {
            dialog() {
                if (!this.dialog) {
                    this.id          = null;
                    this.show_solved = null;
                    this.inquiry     = null;
                    this.reply       = "";
                }
            }
        },
        computed: {
            c_created_at() {
                let current_year = new Date().getFullYear();
                let year         = Number(this.moment(this.inquiry.timestamp).format("YYYY"));
                let format       = current_year === year ? "DD.MM - HH:mm" : "DD.MM.YYYY - HH:mm";
                return this.moment(this.inquiry.timestamp).format(format);
            }
        },
        beforeCreate() {
            this.func.vuetify_dragable_dialogs();
        },
        methods : {
            async open_dialog(id, show_solved) {
                this.id          = id;
                this.show_solved = show_solved;
                this.get_inquiry()
            },
            async get_inquiry() {
                let response = await this.func.ax("/php/administration.php", {
                    func       : "get_inquiry",
                    userid     : this.$user.user.id,
                    token      : this.$user.user.token,
                    caseid     : this.id,
                    show_solved: this.show_solved,
                });

                await this.$root.check_response_permission(response, () => {
                    this.inquiry = response;
                    this.dialog  = true;
                }, response);
            },
            async send_reply() {
                // Reply with the text
                let reply = {
                    userID   : this.$user.user.id,
                    username : this.$user.user.username,
                    text     : this.reply,
                    timestamp: this.moment().format("DD.MM.YYYY HH:mm")
                };

                // Create a backup of the original replies
                let replies = JSON.parse(JSON.stringify(this.inquiry.our_replies));
                replies.push(reply);


                let response = await this.func.ax("/php/administration.php", {
                    func       : "inquiry_reply",
                    userid     : this.$user.user.id,
                    token      : this.$user.user.token,
                    caseid     : this.inquiry.ID,
                    username   : this.$user.user.username,
                    email      : this.inquiry.email,
                    reply      : this.reply.replace(/\n/g, "<br>"),
                    our_replies: replies,
                    language   : this.$lang,
                });

                await this.$root.check_response_permission(response, () => {
                    // If successfull also push the reply in the array
                    this.inquiry.our_replies.push(reply);
                    this.reply = "";
                    this.get_inquiry();
                }, response);

                this.unlock_inquiry_actions++;
            },
            async mark_solved() {
                let response = await this.func.ax("/php/administration.php", {
                    func  : "inquiry_solved",
                    userid: this.$user.user.id,
                    token : this.$user.user.token,
                    caseid: this.inquiry.ID,
                });

                await this.$root.check_response_permission(response, () => {
                    this.inquiry.solved = '1';
                    this.$emit("inquiry_solved", this.inquiry.ID);
                }, response);

                this.unlock_inquiry_actions++;
            },
            async remove_all_reports() {
                let response = await this.func.ax("/php/administration.php", {
                    func  : "remove_all_post_reports",
                    userid: this.$user.user.id,
                    token : this.$user.user.token,
                    postid: this.id,
                });
                await this.$root.check_response_permission(response, () => {
                    this.$emit("all_reports_removed", this.id);
                    this.dialog = false;
                }, response);

                this.unlock_disable_all_reports++;
            }
        },
    }
</script>