<template>
    <div>
        <dialog-post ref="dialog_post" :adminmode="true" @voted="update_post"></dialog-post>
        <!--Search strings-->
        <v-expansion-panels class="mb-5">
            <v-expansion-panel class="expansion-box">
                <v-expansion-panel-header>
                    <span class="font-weight-bold">{{$t("administration.search-fields")}}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="d-flex">
                        <v-text-field
                                v-model="search_creator"
                                append-icon="mdi-magnify"
                                :label="$t('administration.post-overview.search.creator')"
                                :color="$variables.v.theme.secondary"
                                maxlength="30"
                                class="default-textfield-width"
                                @keyup.enter="get_posts"></v-text-field>

                        <v-text-field
                                v-model="search_title"
                                append-icon="mdi-magnify"
                                :label="$t('administration.post-overview.search.title')"
                                :color="$variables.v.theme.secondary"
                                maxlength="50"
                                class="default-textfield-width ml-10"
                                @keyup.enter="get_posts"></v-text-field>

                        <v-text-field
                                v-model="search_limit"
                                append-icon="mdi-magnify"
                                :label="$t('administration.post-overview.search.limit')"
                                :color="$variables.v.theme.secondary"
                                maxlength="3"
                                class="small-textfield-width ml-10"
                                @keyup.enter="get_posts"></v-text-field>
                    </div>
                    <div class="d-flex">
                        <v-checkbox class="mr-3"
                                    v-model="hide_disabled"
                                    :label="$t('administration.post-overview.search.hide-disabled')"
                                    :color="$variables.v.theme.secondary"
                                    @change="get_posts"></v-checkbox>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>


        <!--Hide columns-->
        <v-expansion-panels class="mb-5">
            <v-expansion-panel class="expansion-box">
                <v-expansion-panel-header>
                    <span class="font-weight-bold">{{$t("administration.hide-show-columns")}}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="d-flex">
                        <v-checkbox class="mr-3"
                                    v-model="show_points"
                                    :label="$t('administration.post-overview.show-points')"
                                    :color="$variables.v.theme.secondary"
                                    @change="get_posts"></v-checkbox>

                        <v-checkbox class="mr-3"
                                    v-model="show_disabled"
                                    :label="$t('administration.post-overview.show-disabled')"
                                    :color="$variables.v.theme.secondary"
                                    @change="get_posts"></v-checkbox>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>


        <v-data-table
                :headers="c_headers"
                :items="posts"
                :page.sync="page"
                :items-per-page="items_per_page"
                hide-default-footer
                sort-by="reports"
                sort-desc
                class="disable-select"
                @page-count="pageCount = $event"
                @click:row="open_post">
            <template slot="no-data">
                <div></div>
            </template>
            <template v-slot:item.disabled="{ item }">
                {{item.disabled === "1"}}
            </template>
        </v-data-table>

        <div class="text-center pt-2">
            <v-pagination
                    v-model="page"
                    :length="pageCount"
                    :color="$variables.v.theme.primary"
            ></v-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                posts               : [],
                page                : 1,
                pageCount           : 0,
                items_per_page      : 20,
                search_creator      : "",
                search_title        : "",
                search_limit        : "100",
                search_limit_default: 100,
                hide_disabled       : true,
                show_points         : false,
                show_disabled       : false,
            }
        },
        computed: {
            c_search_limit() {
                return isNaN(this.search_limit.trim()) ? this.search_limit_default : this.search_limit.trim();
            },
            c_headers() {

                let headers = [
                    {text: this.$t("administration.post-overview.table.postid"), value: "ID"},
                    {text: this.$t("administration.post-overview.table.poster"), value: "username"},
                    {text: this.$t("administration.post-overview.table.title"), value: "title"},
                ];
                if (this.show_points)
                    headers.push({text: this.$t("administration.post-overview.table.points"), value: "points"});
                if (this.show_disabled)
                    headers.push({text: this.$t("administration.post-overview.table.deleted"), value: "disabled"});

                return headers;
            }
        },
        created() {
            this.get_posts();
        },
        methods : {
            async get_posts() {
                let response = await this.func.ax("/php/administration.php", {
                    func          : "get_posts",
                    userid        : this.$user.user.id,
                    token         : this.$user.user.token,
                    search_creator: this.search_creator.trim(),
                    search_title  : this.search_title.trim(),
                    hide_disabled : this.hide_disabled,
                    search_limit  : this.c_search_limit,
                });
                await this.$root.check_response_permission(response, () => {
                    this.posts = response;
                }, response);
            },
            open_post(post) {
                this.$refs.dialog_post.open_dialog(post.ID);
            },
            update_post(postid, action, points) {
                this.posts.find(x => x.ID === postid).action = action;
                this.posts.find(x => x.ID === postid).points = points;
            },
        },
        watch   : {
            page() {
                if (this.pageCount === this.pageCount)
                    this.get_posts(true);
            },
        },
    }
</script>